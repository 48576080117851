import React from "react";
import settings from "config/baseSettings";
import { useEffect, useState } from "react";
import { axiosInstance as axios } from "services";
import { addSubcategory, deleteVideo, uploadedVideo } from "utils/setup";
import { GET_ALL_ADMIN_VIDEO } from "utils/utils";
import { randomKey } from "../../utils/utils";

const url = settings.baseUrl;

const useSetup = () => {
  const [selectCategory, setSelectCategory] = useState("");
  const [category, setCategory] = useState("");
  const [addCategory, setAddCategory] = useState(false);
  const [allScreen, setAllScreen] = useState([]);
  const [uploadVideo, setUploadVideo] = useState("");
  const [allVideo, setAllVideo] = useState([]);
  const [description, setDesCription] = useState({});
  const [progressBar, setProgressBar] = useState();
  const [isDelete, setIsDelete] = useState(false);

  useEffect(() => {
    if (selectCategory) {
      getAllVideo(selectCategory);
    }
  }, [selectCategory]);

  const getAllVideo = (selectCategory) => {
    const body = {
      category: selectCategory,
    };
    return axios
      .post(`${url}${GET_ALL_ADMIN_VIDEO}`, body)

      .then((response) => {
        console.log("response", response);
        setAllVideo(response?.data?.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };
  const UploadVideos = (id, subScreens) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", uploadVideo);
    bodyFormData.append("id", id);
    bodyFormData.append(
      "descriptions",
      description[subScreens.trim().split(" ").join("_").toLowerCase()]
    );

    uploadedVideo(
      bodyFormData,
      getAllVideo,
      selectCategory,
      setProgressBar,
      setDesCription,
      setUploadVideo
    );
  };

  const addSubCategoeies = () => {
    const body = {
      category: selectCategory,
      categoryType: category,
    };
    addSubcategory(body, getAllVideo, selectCategory, setAddCategory);
  };

  const deleteIndivisualVideo = (id) => {
    deleteVideo(id, getAllVideo, selectCategory, setIsDelete);
  };
  const displaypTypeOptionsScreen = () => {
    const screenAllData = allScreen?.filter((elem) => {
      return elem.isDeleted !== true;
    });

    return screenAllData?.map((elem) => {
      return (
        <option value={elem._id} key={randomKey}>
          {elem.screenName}
        </option>
      );
    });
  };

  return {
    selectCategory,
    setSelectCategory,
    setCategory,
    category,
    addCategory,
    setAddCategory,
    displaypTypeOptionsScreen,
    setUploadVideo,
    uploadVideo,
    UploadVideos,
    addSubCategoeies,
    allVideo,
    description,
    setDesCription,
    progressBar,
    deleteIndivisualVideo,
    setIsDelete,
    isDelete,
  };
};
export default useSetup;
