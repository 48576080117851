import React, { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
// import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { useHistory } from 'react-router-dom';
import InitialLoader from 'components/InitilalLoader';
import { Dropdown } from 'react-bootstrap';
import useBilling from 'customHooks/OrSubscriberAndBillingsHook/useBilling';
import "./billing.scss"
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Alert } from 'react-bootstrap';

const TableAdmin = (props) => {
  const [cell, setCell] = useState(false)
  const [show, setShow] = useState(false);
  const [companyId, setCompanyId] = useState()
  const [payStatus, setPayStatus] = useState()
  const [alertMsg, setAlertMsg] = useState(false)


  const handleClose = () => {
    setCompanyId(null)
    setPayStatus(null)
    setShow(false)
  };

  const handle_save_changes = () => {
    update_subscription_status({
      companyId: companyId,
      paymentStatus: payStatus
    }).then(() => {
      setUpdateStatus(!updateStatus)
      setShow(false)
    })
  }
  const { isLoading, isError, update_subscription_status } = useBilling()
  let { products, setUpdateStatus, updateStatus } = props

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {

    },
    onSizePerPageChange: function (page, sizePerPage) {
    }
  });

  let product = products.map(val => {
    let {
      referenceId,
      paymentStatus,
      paymentPlanUser,
      paymentType,
      dateOfRecentPayment,
      company = [{ _id: '', companyName: '' }]
    } = val

    const [firstCompany = { _id: '', companyName: '' }] = company

    return {
      companyId: firstCompany._id,
      // id: referenceId,
      companyName: firstCompany.companyName,
      paymentStatus: paymentStatus,
      // paymentType: paymentType,
      paymentPlanUser: paymentPlanUser,
      paymentAmount: "19.99",
      DateOfPayment: dateOfRecentPayment ? new Date(dateOfRecentPayment).toDateString() : ''
    };
  })


  const handleDropdownSelect = (rowId, eventKey, row) => {
    // Perform any desired action based on the selected dropdown item here.
    setShow(!show)
    setCompanyId(row.companyId)
    setPayStatus(eventKey)
    // console.log({rowId,eventKey,row})
    // update_subscription_status({
    //   companyId: row.companyId,
    //   paymentStatus: eventKey
    // })
    // setUpdateStatus(!updateStatus)
  };

  const history = useHistory()

  const handleRowDoubleClick = (e, row, rowIndex, columnName) => {
    // Perform the desired action when a row is clicked
    console.log({ e, row, rowIndex, cell, columnName })
    if (columnName) {
      let selected_product = products.filter(
        (val) => val.companyId == row.companyId
      );
      console.log({ selected_product });
      history.push({
        pathname: "/indivisualprofile",
        state: { data: row, selected_product },
      });
      // You can modify the action based on your requirements, such as redirecting to another page or showing more information.
    }
  };

  const on_mouse_enter = (e, row, rowIndex, columnName) => {
    if (columnName) {
      e.target.classList.add('hover-effect')
    }
  }

  const columns = [
    // {
    //   dataField: "id",
    //   text: "Payment Id",
    //   headerStyle:{ width: "20%", position: 'center', top: 0, zIndex: 1, background: '#fff' } 
    // },
    // {
    //   dataField: "companyId",
    //   text: "Company Id",
    // },
    {
      dataField: "companyName",
      filter: textFilter({
        placeholder: "Enter Company Name"
      }),
      formatter: (cell, row, rowIndex, formatExtraData) => {
        console.log({ cell, row, rowIndex, formatExtraData })
        return (
          <div
            onClick={(e) =>
              formatExtraData.handleRowDoubleClick(e, row, rowIndex, 'name')
            }
            onMouseEnter={(e) => on_mouse_enter(e, row, rowIndex, 'name')}
          >
            {cell}
          </div>
        );
      },
      formatExtraData: { handleRowDoubleClick, on_mouse_enter },
    },
    {
      dataField: "paymentStatus",
      filter: textFilter({
        placeholder: "Enter Status"
      }),
      style: {
        fontWeight: 'bold', // Make the cell text bold
      }
    },
    // {
    //   dataField: "paymentType",
    //   text: "Payment Type",
    // },
    {
      dataField: "paymentPlanUser",
      text: "Payment Plan (number of users)",
    },
    // {
    //   dataField: "paymentAmount",
    //   text: "Payment Amount",
    // },
    // {
    //   dataField: "DateOfPayment",
    //   text: "Date of Payment",
    // },
    // {
    //   dataField: "loginTime",
    //   text: "Last Login Time",
    // },
    {
      dataField: "createdAt",
      text: "Join Date",
    },
    {
      dataField: 'dropdown',
      text: 'Action',
      formatter: (cellContent, row) => {
        return (
          <Dropdown onSelect={(eventKey, event) => handleDropdownSelect(row.id, eventKey, row)}>
            <Dropdown.Toggle id={`dropdown-${row.companyId}`} style={{ backgroundColor: "#0d6efd" }}>
              {payStatus ? payStatus : row.paymentStatus}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item eventKey="Paid">Paid</Dropdown.Item>
              <Dropdown.Item eventKey="Unpaid">Unpaid</Dropdown.Item>
              <Dropdown.Item eventKey="Waived/Comp">Waived/Comp</Dropdown.Item>
              <Dropdown.Item eventKey="Frozen 1">Frozen 1</Dropdown.Item>
              <Dropdown.Item eventKey="Frozen 2">Frozen 2</Dropdown.Item>
              {/* <Dropdown.Item eventKey="Deactivated/Unsubscribed">Deactivated/Unsubscribed</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  console.log({ payStatus })
  if (!product || !products || isLoading) {
    return <InitialLoader />;
  }
  if (!products.length) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '60vh' }}>
        <h2 style={{ alignSelf: 'center' }}>
          Currently there is no company has subsci
        </h2>
      </div>
    )
  }

  return (
    <>
      {alertMsg && <Alert variant='info'>Double click to see indivisual profile</Alert>}
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Payment Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to update the status</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handle_save_changes}>
            Update Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {product && products && products.length && product.length && (
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={product}
          columns={columns}
          pagination={pagination}
          filter={filterFactory()}
        />
      )}
    </>
  );
}

export default TableAdmin