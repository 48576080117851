import { useState } from "react";
import { toast } from "react-toastify";
import { axios as axiosNoAuth } from "services";

const useStateTax = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState();
  const [stateAndCounties, setStateAndCounties] = useState([]);
  const [zipCodes, setZipCodes] = useState([]);
  const [currentStateAndCounty, setCurrentStateAndCounty] = useState({
    stateName: "",
    countyId: "",
  });

  const [uploadError, setUploadError] = useState(null);
  const [uploadResponse, setUploadResponse] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  //   useEffect(() => {
  //     getCountyAndState();
  //   }, []);

  const getCountyAndState = (stateName = "New York", countyId = "") => {
    setCurrentStateAndCounty({
      stateName: stateName,
      countyId: countyId,
    });
    // Ensure stateName is provided
    if (!stateName) {
      console.error("State name is required.");
      return Promise.reject(new Error("State name is required."));
    }

    // Construct request body
    const body = {
      stateName: stateName,
      countyId: countyId,
    };

    // Encode URL parameters
    const params = new URLSearchParams(body);

    // Construct URL
    const url = `/admin/stateAndCountyTax?${params.toString()}`;

    // Set loading state
    setIsLoading(true);

    // Make API request
    return axiosNoAuth
      .get(url)
      .then((res) => {
        const results = res.data.result;
        if (results?.counties?.length) {
          if (countyId) {
            const zipCodes = results?.counties?.[0]?.zipCodes;
            setZipCodes(zipCodes);
            setSelectedCounty(results?.counties?.[0]);
            setCurrentState(results);
          } else {
            setStateAndCounties(results?.counties);
            setCurrentState(results);
          }
        } else {
          setStateAndCounties([]);
          setSelectedCounty(null);
          setCurrentState(results);
        }
      })
      .catch((err) => {
        console.error("Error fetching state and county data:", err);
        setStateAndCounties([]);
        return Promise.reject(err);
      })
      .finally(() => {
        // Reset loading state
        setIsLoading(false);
      });
  };

  const updateStateTax = (body) => {
    setIsLoading(true);
    return axiosNoAuth
      .post("/admin/stateAndCountyTax/createTax", body)
      .then((res) => {
        if (res.data.success) {
          toast.success("State specific tax data updated");
          getCountyAndState(
            currentStateAndCounty.stateName,
            currentStateAndCounty.countyId
          );
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateState = (body) => {
    setIsLoading(true);
    return axiosNoAuth
      .post("/admin/stateAndCountyTax/state", body)
      .then((res) => {
        if (res.data.success) {
          toast.success("State specific tax data updated");
          getCountyAndState(
            currentStateAndCounty.stateName,
            currentStateAndCounty.countyId
          );
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addNewCounty = async (body) => {
    setIsLoading(true);
    try {
      try {
        const res = await axiosNoAuth.post(
          "/admin/stateAndCountyTax/createCounty",
          body
        );
        if (res.data.success) {
          toast.success("County added");
          getCountyAndState(
            currentStateAndCounty.stateName,
            currentStateAndCounty.countyId
          );
        }
      } catch (err) {
        console.log(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // update county tax data
  const updateCountyTax = (body) => {
    setIsLoading(true);
    return axiosNoAuth
      .post("/admin/stateAndCountyTax/updateCountyTax", body)
      .then((res) => {
        if (res.data.success) {
          toast.success("County data updated");
          // getCountyAndState();
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const uploadZipCodesWithStateAndCounty = (body) => {
    setIsLoading(true);
    return axiosNoAuth
      .post("/admin/stateAndCountyTax/upload", body)
      .then((res) => {
        if (res.data.success) {
          toast.success("County data updated");
          getCountyAndState(
            currentStateAndCounty.stateName,
            currentStateAndCounty.countyId
          );
          setUploadResponse(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
        setUploadError(err);
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    getCountyAndState,
    currentState,
    stateAndCounties,
    updateCountyTax,
    addNewCounty,
    zipCodes,
    uploadZipCodesWithStateAndCounty,
    uploadResponse,
    uploadError,
    isLoading,
    selectedCounty,
    updateStateTax,
    updateState,
    setStateAndCounties,
  };
};

export default useStateTax;
