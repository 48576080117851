import { toast } from "react-toastify";
import { axios as axiosNoAuth } from "services";
import { useEffect } from "react";
import { useState } from "react";
import {
  edit_admin_email_template,
  get_all_admin_email_template,
} from "api/api";

const useClientEmail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState();
  const [emailTemplate, setEmailTemplate] = useState();

  useEffect(() => {
    get_all_email_data();
  }, []);

  const get_all_email_data = () => {
    setIsLoading(true);
    return get_all_admin_email_template("/admin/clientEmail/getAllEmail")
      .then((res) => {
        if (res.data.success) {
          setEmailTemplate(res?.data.result);
        }
      })
      .catch((err) => {
        setIsError(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const edit_email = (body) => {
    setIsLoading(true);
    return edit_admin_email_template("/admin/clientEmail/editEmail", body)
      .then((res) => {
        if (res.data.success) {
          toast.success("Email Updated");
        } else {
          toast.error("Email couldn't be updated");
        }
      })
      .catch((err) => {
        toast.error("Something went wrong");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return {
    emailTemplate,
    isError,
    isLoading,
    edit_email,
  };
};

export default useClientEmail;
