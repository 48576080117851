import settings from "config/baseSettings";
import { useEffect, useState } from "react";
import { axiosInstance as axios } from "services";
import {
  changeSchedulingTimeProjectManagement,
  changeSchedulingTimeRepair,
  changeSchedulingTimeRepairGap,
  changeSchedulingTimeSales,
  changeSchedulingTimeSalesGap,
} from "utils/SchedulingModal/scheduling";
import {
  GET_SCEDULING_TIME,
  GET_SCEDULING_TIME_BY_ID
} from "utils/utils";
const url = settings.baseUrl;

const useSchedulingModal = () => {
  const [additionalRepairTime, setAdditionalRepairtime] = useState(0);
  const [additionalSalesTime, setAdditionalSalesTime] = useState(0);
  const [additionalRepairGapTime, setAdditionalRepairGapTime] = useState(0);
  const [additionalSalesGapTime, setAdditionalSalesGapTime] = useState(0);
  const [additionalProjectmanagementTime, setAdditionalProjectManagementTime] =
    useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [showSalesSave, setShowSalesSave] = useState(false);
  const [showRepairSave, setShowRepairSave] = useState(false);
  const [showProjectSave, setShowProjectSave] = useState(false);
  const [showSalesGapSave, setShowSalesGapSave] = useState(false);
  const [showRepairGapSave, setShowRepairGapSave] = useState(false);

  const [companyId, setCompanyId] = useState("0");

  /**
   * Scheduling Modal
   * Start all apis of scheduling modal
   */

  useEffect(() => {
    axios
      .get(`${url}${GET_SCEDULING_TIME}`)
      .then((response) => {
        setCompanyId(response?.data?.result[0]?.companyId);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);
  /**
   * Scheduling Modal
   * Start  apis of scheduling modal by id
   */

  useEffect(() => {
    axios
      .get(`${url}${GET_SCEDULING_TIME_BY_ID}${companyId}`)
      .then((response) => {
        setAdditionalRepairtime(response?.data?.result?.repair_time);
        setAdditionalSalesTime(response?.data?.result?.sales_time);
        setAdditionalProjectManagementTime(
          response?.data?.result?.project_management_time
        );
        setAdditionalRepairGapTime(response?.data?.result?.repair_gap_time);
        setAdditionalSalesGapTime(response?.data?.result?.sales_gap_time);

        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);

  /**
   * Scheduling Modal
   * Start all apis of scheduling modal
   */
  const increaseProjectManager = (increase = true) => {
    let user = additionalProjectmanagementTime;
    if (increase) {
      user += 1;
    } else if (user >= 1) {
      user -= 1;
    }
    setAdditionalProjectManagementTime(user);
  };
  const increaseSales = (increase = true) => {
    let user = additionalSalesTime;
    console.log({ user });
    if (increase) {
      user += 1;
    } else if (user >= 1) {
      user -= 1;
    }
    setAdditionalSalesTime(user);
  };
  const increaseRepair = (increase = true) => {
    let user = additionalRepairTime;
    if (increase) {
      user += 1;
    } else if (user >= 1) {
      user -= 1;
    }
    setAdditionalRepairtime(user);
  };
  /**
   *
   * for gap time repairs and sales
   */
  const increaseSalesGap = (increase = true) => {
    let user = additionalSalesGapTime;
    if (increase) {
      user += 1;
    } else if (user >= 1) {
      user -= 1;
    }
    setAdditionalSalesGapTime(user);
  };
  const increaseRepairGap = (increase = true) => {
    let user = additionalRepairGapTime;
    if (increase) {
      user += 1;
    } else if (user >= 1) {
      user -= 1;
    }
    setAdditionalRepairGapTime(user);
  };
  /**
   *
   */
  const handleOnClickSalesSave = () => {
    changeSchedulingTimeSales(
      companyId,
      additionalSalesTime,
      setShowSalesSave,
      setIsLoading
    );
  };
  const handleOnClickRepairSave = () => {
    changeSchedulingTimeRepair(
      companyId,
      additionalRepairTime,
      setShowRepairSave,
      setIsLoading
    );
  };
  const handleOnClickProjectSave = () => {
    changeSchedulingTimeProjectManagement(
      companyId,
      additionalProjectmanagementTime,
      setShowProjectSave,
      setIsLoading
    );
  };
  /**
   *Click on save of repair and sales gap
   */
  const handleOnClickRepairGapSave = () => {
    changeSchedulingTimeRepairGap(
      companyId,
      additionalRepairGapTime,
      setShowRepairGapSave,
      setIsLoading
    );
  };
  const handleOnClickSalesGapSave = () => {
    changeSchedulingTimeSalesGap(
      companyId,
      additionalSalesGapTime,
      setShowSalesGapSave,
      setIsLoading
    );
  };
  /**
   *
   */
  return {
    increaseProjectManager,
    increaseSales,
    increaseRepair,
    setAdditionalRepairtime,
    additionalRepairTime,
    setAdditionalSalesTime,
    additionalSalesTime,
    setAdditionalProjectManagementTime,
    additionalProjectmanagementTime,
    setIsLoading,
    isLoading,

    showRepairSave,
    setShowRepairSave,
    showProjectSave,
    setShowProjectSave,
    showSalesSave,
    setShowSalesSave,
    handleOnClickSalesSave,
    handleOnClickRepairSave,
    handleOnClickProjectSave,
    increaseSalesGap,
    increaseRepairGap,
    handleOnClickRepairGapSave,
    handleOnClickSalesGapSave,
    setShowSalesGapSave,
    setShowRepairGapSave,
    showSalesGapSave,
    showRepairGapSave,
    setAdditionalRepairGapTime,
    setAdditionalSalesGapTime,
    additionalRepairGapTime,
    additionalSalesGapTime,
  };
};
export default useSchedulingModal;
