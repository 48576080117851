import React, { useEffect, useState } from "react";
import "./select-state.scss";
import { Form } from "react-bootstrap";
import RequiredText from "./required-text";

export default function County({
  label,
  value,
  onChange,
  disabled,
  counties,
  required,
  ...props
}) {
  const [states, setStates] = useState([]);
  const showOptions = (list) =>
    list.map((county) => (
      <option
        style={{ textTransform: "capitalize" }}
        value={county.countyId}
        key={county.countyId}
      >
        {county.county}
      </option>
    ));

  useEffect(() => {
    setStates(counties);
  }, [counties]);

  return (
    <Form.Group>
      <Form.Label>
        {label} {required && <RequiredText>*</RequiredText>}
      </Form.Label>

      <Form.Control
        // className={`form-control select-state ${
        //   (errAt === name || errors[name]) && "error"
        // }`}
        as="select"
        onChange={onChange}
        name={label}
        value={value}
        defaultValue=""
        {...props}
      >
        <option disabled hidden value="">Choose...</option>
        {showOptions(states)}
      </Form.Control>
    </Form.Group>
  );
}
