import React from "react";
import { getAllScreen } from "api/api";
import settings from "config/baseSettings";
import { useEffect, useState } from "react";
import { axiosInstance as axios } from "services";
import {
  addSubcategory,
  addSubcategorySetup,
  deleteSubscreen,
  deleteVideo,
  saveSubscreen,
  uploadedVideo,
} from "utils/help";
import { GET_ALLVIDEO } from "utils/utils";
import { randomKey } from "../../utils/utils";
const url = settings.baseUrl;

const useHelp = () => {
  const [selectCategory, setSelectCategory] = useState("");
  const [category, setCategory] = useState("");
  const [addCategory, setAddCategory] = useState(false);
  const [allScreen, setAllScreen] = useState([]);
  const [allVideo, setAllVideo] = useState([]);
  const [progressBar, setProgressBar] = useState();
  const [isDelete, setIsDelete] = useState(false);
  const [videoId, setVideoId] = useState("");
  const [editSubscreen, setEditSubscreen] = useState("");
  const [selectSetup, setSelectSetup] = useState("");
  const [setupCategory, setSetupCategory] = useState("");
  const [addSetupCategory, setAddSetupCategory] = useState(false);
  const [isDeleteSubScreen, setIsDeleteSubscreen] = useState(false);
  const [subScreenId, setsubScreenId] = useState("");
  const [subScreenName, setSubScreenName] = useState("");

  useEffect(() => {
    getAllScreen("/admin/permission/screen")
      .then((response) => {
        setAllScreen(response?.data?.result);

        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, []);

  useEffect(() => {
    if (selectCategory) {
      setAllVideo([]);
      if (selectCategory !== "First Time Setup") {
        getAllVideo({ id: selectCategory });
      }
    }
  }, [selectCategory]);
  useEffect(() => {
    if (selectSetup) {
      getAllVideo({
        category: selectSetup,
      });
    }
  }, [selectSetup]);
  const getAllVideo = (body) => {
    return axios
      .post(`${url}${GET_ALLVIDEO}`, body)

      .then((response) => {
        console.log("response", response);
        setAllVideo(response?.data?.result);
        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const UploadVideos = (
    id,
    subScreens,
    description,
    setDesCription,
    setUploadVideo,
    uploadVideo,
    inputRef
  ) => {
    const bodyFormData = new FormData();
    bodyFormData.append("file", uploadVideo);
    bodyFormData.append("id", id);
    bodyFormData.append("descriptions", description);

    uploadedVideo(
      bodyFormData,
      getAllVideo,
      selectCategory,
      selectSetup,
      setProgressBar,
      setDesCription,
      setUploadVideo,
      inputRef
    );
  };

  const addSubCategoeies = () => {
    const body = {
      screenId: selectCategory,
      subScreens: category,
    };
    addSubcategory(
      body,
      getAllVideo,
      selectCategory,
      setAddCategory,
      setCategory
    );
  };

  const addSubCategoeiesSetup = () => {
    const body = {
      category: selectSetup,
      subScreens: setupCategory,
    };
    addSubcategorySetup(
      body,
      getAllVideo,
      selectSetup,
      setAddSetupCategory,
      setSetupCategory
    );
  };

  const deleteIndivisualVideo = (id) => {
    deleteVideo(id, getAllVideo, selectCategory, selectSetup, setIsDelete);
  };
  const displaypTypeOptionsScreen = () => {
    const screenAllData = allScreen?.filter((elem) => {
      return elem.isDeleted !== true;
    });

    return screenAllData?.map((elem) => {
      return (
        <option key={randomKey} value={elem._id}>
          {elem.screenName}
        </option>
      );
    });
  };
  const handleOnChangeCategory = (e) => {
    setSelectCategory(e.target.value);
    setSelectSetup("");
  };

  const handleOnClickDeleteSubscreen = (subScreenName, subScreenId) => {
    if (selectCategory === "First Time Setup") {
      const body = {
        category: selectSetup,
        id: subScreenId,
        subScreens: subScreenName,
      };
      deleteSubscreen(
        selectCategory,
        selectSetup,
        getAllVideo,
        setIsDeleteSubscreen,
        body
      );
    } else {
      const body = {
        screenId: selectCategory,
        id: subScreenId,
        subScreens: subScreenName,
      };
      deleteSubscreen(
        selectCategory,
        selectSetup,
        getAllVideo,
        setIsDeleteSubscreen,
        body
      );
    }
  };
  const handleOnClickSave = (data, setIsEdit) => {
    if (selectCategory === "First Time Setup") {
      const body = {
        category: selectSetup,
        id: data._id,
        subScreens: editSubscreen,
      };
      saveSubscreen(selectCategory, selectSetup, getAllVideo, body, setIsEdit);
    } else {
      const body = {
        screenId: selectCategory,
        id: data._id,
        subScreens: editSubscreen,
      };
      saveSubscreen(selectCategory, selectSetup, getAllVideo, body, setIsEdit);
    }
  };
  return {
    selectCategory,
    setSelectCategory,
    setCategory,
    category,
    addCategory,
    setAddCategory,
    displaypTypeOptionsScreen,
    UploadVideos,
    addSubCategoeies,
    allVideo,

    progressBar,
    deleteIndivisualVideo,
    setIsDelete,
    isDelete,
    setAddSetupCategory,
    addSetupCategory,
    setSetupCategory,
    setupCategory,
    setSelectSetup,
    selectSetup,
    setVideoId,
    videoId,
    setEditSubscreen,
    editSubscreen,
    handleOnChangeCategory,
    addSubCategoeiesSetup,
    handleOnClickDeleteSubscreen,
    isDeleteSubScreen,
    setIsDeleteSubscreen,
    setsubScreenId,
    setSubScreenName,
    subScreenName,
    subScreenId,

    handleOnClickSave,
  };
};
export default useHelp;
