import {
  AddPermisions,
  AddScreen,
  AddUserRoles,
  AdminPermisions,
  basePlanUser,
  getAllAdminPermisions,
  getAllOnBoardingCharge,
  getAllPermisions,
  getAllPermisionsDelete,
  getAllPlans,
  getAllScreen,
  getAllScreenDelete,
  getAllUserRoles,
  getAllUserRolesDelete,
  update_onboarding_charge,
} from "api/api";
import InitialLoader from "components/InitilalLoader";
import MainComponent from "components/MainComponent";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import Select from 'react-select';

import "./Subscription.scss";
import MangeTaxes from "./StateTax";
import { APP_DEFAULT_ROLES } from "utils/const";

const transformPermissionsToOptions = (permissions, selectedPermissions) => {
  if (!permissions) return [];
  
  const grouped = permissions.reduce((acc, permission) => {
    const group = permission.screen || 'Other';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(permission);
    return acc;
  }, {});

  return Object.entries(grouped).map(([screen, perms]) => ({
    label: screen,
    options: perms.map(perm => ({
      value: perm.key,
      label: perm.option,
      isOptional: perm.isOptional,
      screen: perm.screen || 'Other',
      combinedLabel: `${perm.screen || 'Other'} - ${perm.option}`
    }))
  }));
};

const Subscription = () => {
  const [additionalUser, setAdditionalUser] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [editBasePlan, setEditBasePlan] = useState("");
  const [basePlan, setBasePlan] = useState("");
  const [oneTimeCharge, setOneTimeCharge] = useState(0);
  const [allBasePlan, setAllBasePlan] = useState([]);
  const [pricePerAdditionalUser, setPricePerAdditionalUser] = useState("");
  const [setOnBoardingCharge] = useState();
  const [editOnBoarding, setEditOnBoarding] = useState(false);
  const [taxes, setTaxes] = useState([]);

  /**
   * user role state
   */
  const [selectUserRole, setSelectUserRole] = useState("");
  const [addUserRoles, setAddUserRoles] = useState("");
  const [userRole, setUserRole] = useState("");
  const [allUserRole, setAllUserRole] = useState([]);

  /**
   *user permisions state
   */
  const [selectPermision, setSelectPermision] = useState([]);
  const [allPermision, setAllPermision] = useState([]);

  /**
   *user screen state
   */
  const [selectScreen, setSelectScreen] = useState("");

  useEffect(() => {
    setAdditionalUser(allBasePlan?.baseplanUsers);
    setBasePlan(allBasePlan?.baseplanPrice);
    setOneTimeCharge(allBasePlan?.oneTimeSetipPrice);
    setPricePerAdditionalUser(allBasePlan?.pricePerAdditionalUser);
  }, [allBasePlan]);

  useEffect(() => {
    getAllPlans("/admin/baseplanuser")
      .then((response) => {
        setAllBasePlan(response?.data?.result);
        console.log("response", response);
        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  }, []);

  useEffect(() => {
    getAllOnBoardingCharge("/admin/baseplanuser/getOnBoardingCharge")
      .then((response) => {
        console.log("responseOnBoarding", response);

        setOnBoardingCharge(response?.data?.result);
        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);
        console.log({ error });
        if (error.response) {
          return { error: error.response };
        }
      });
  }, []);

  const handleOnClickSave = () => {
    setIsLoading(true);
    const body = {
      // companyId: "0",
      baseplanUsers: additionalUser,
      baseplanPrice: basePlan,
      pricePerAdditionalUser: +pricePerAdditionalUser,
      oneTimeSetipPrice: oneTimeCharge,
    };
    setEditBasePlan(!editBasePlan);
    basePlanUser("/admin/baseplanuser", body)
      .then((response) => {
        setIsLoading(false);
        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  /**
   * user role
   */
  const handleOnClickSaveUserRole = async () => {
    try {
      setIsLoading(true);
      const body = {
        roleName: userRole,
      };
      const response = await AddUserRoles("/admin/permission/addrole", body);
      if (response?.data?.success) {
        setUserRole("");
        setAddUserRoles(false);
        toast.success("User role added successfully!");
      } else {
        toast.error(response?.data?.error);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getAllUserRoles("/admin/permission/default-roles")
      .then((response) => {
        console.log("responseUserRole", response);
        setAllUserRole(response?.data?.result);

        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);

  const handleOnClickDeleteUserRole = () => {
    setIsLoading(true);

    const body = {
      isDeleted: true,
    };
    setAddUserRoles(addUserRoles);
    setSelectUserRole(!selectUserRole);
    getAllUserRolesDelete("/admin/permission/deleterole/", selectUserRole, body)
      .then((response) => {
        setIsLoading(false);

        return { error: null, response };
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response) {
          return { error: error.response };
        }
      });
  };

  const displaypTypeOptionsUserRoles = () => {
    return allUserRole?.map((elem, id) => {
      return (
        <option key={elem._id} value={elem._id}>
          {elem.name}
        </option>
      );
    });
  };

  /**
   *user permisions
   */
  useEffect(() => {
    getAllPermisions("/admin/permission/permissions")
      .then((response) => {
        setAllPermision(response?.data?.result);

        return { error: null, response };
      })
      .catch((error) => {
        if (error.response) {
          return { error: error.response };
        }
      });
  }, [isLoading]);

  const increaseUser = (increase = true) => {
    let user = additionalUser;
    if (increase) {
      user += 1;
    } else {
      user -= 1;
    }
    setAdditionalUser(user);
  };

  const handleOnClickSaveAdminPermision = async () => {
    try {
      setIsLoading(true);
      const body = {
        permissions: selectPermision.map((p) => p.value),
        role: selectUserRole,
      };

      const response = await AdminPermisions(
        "/admin/permission/updateDefaultRole",
        body
      );
      if (response?.data?.success) {
        setSelectUserRole("");
        setSelectPermision([]);
        toast.success("Permission updated successfully!");
      } else {
        toast.error(response?.data?.message);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectUserRole = (role) => {
    setSelectUserRole(role);
    const selectedRole = allUserRole.find((r) => r._id === role);
    if (selectedRole) {
      const allSelectedPermision = allPermision
        .filter((p) => selectedRole.permissions.includes(p.key))
        .map((p) => ({
          value: p.key,
          label: p.option,
          isOptional: p.isOptional,
          screen: p.screen || 'Other',
          combinedLabel: `${p.screen || 'Other'} - ${p.option}`
        }));
      setSelectPermision(allSelectedPermision);
    } else {
      setSelectPermision([]);
    }
  };

  const getIdx = (list, id) => list.findIndex((data) => data._id === id);

  const addTax = (data) => {
    setTaxes([...taxes, data]);
  };

  const editTax = (idx, data) => {
    taxes.splice(getIdx(taxes, idx), 1, data);
    setTaxes([...taxes]);
  };

  const deleteTax = (idx) => {
    taxes.splice(getIdx(taxes, idx), 1);
    setTaxes([...taxes]);
  };

  const handlePermissionChange = (selected) => {
    // If nothing is selected, allow clearing all
    if (!selected || selected.length === 0) {
      setSelectPermision([]);
      return;
    }

    // Get the newly selected/deselected option by comparing with current selection
    const newSelection = selected.find(
      option => !selectPermision.find(existing => existing.value === option.value)
    );

    if (newSelection) {
      // Adding a new selection
      if (newSelection.isOptional) {
        // If optional permission, simply add it
        setSelectPermision(selected);
        return;
      }

      // For non-optional permissions, enforce one per screen
      const updatedSelection = selectPermision.filter(
        option => 
          option.isOptional ||
          option.screen !== newSelection.screen
      );
      
      // Add the new selection
      updatedSelection.push(newSelection);
      setSelectPermision(updatedSelection);
    } else {
      // Handling deselection
      const removedOption = selectPermision.find(
        option => !selected.find(newOpt => newOpt.value === option.value)
      );

      if (!removedOption) return;

      // Allow deselection in all cases
      setSelectPermision(selected);
    }
  };

  return (
    <MainComponent>
      <div className="_container_right _user_page">
        <div className="__proposal">
          {isLoading ? (
            <InitialLoader />
          ) : (
            <div style={{ margin: "2% 2% 0" }}>
              <Row style={{ marginBottom: "5%" }}>
                <Col md="3">
                  <Row>
                    <Col md="6">
                      <h6>Base Plan Per Month</h6>
                    </Col>
                    <Col md="5">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          onChange={(e) => setBasePlan(e.target.value)}
                          value={basePlan}
                          disabled={!editBasePlan}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    {/* <Col md="3">
                    <button className="btn btn-primary">Edit</button>
                  </Col> */}
                  </Row>
                </Col>
                <Col md="4">
                  <Row>
                    <Col md="5">
                      <h6>Number of users included in base plan</h6>
                    </Col>
                    <Col md="3">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          value={additionalUser}
                          disabled
                          onChange={(e) => setAdditionalUser(+e.target.value)}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="2">
                      <button
                        disabled={additionalUser === 1 || !editBasePlan}
                        type="button"
                        className="btn btn-outline-dark"
                        style={{ margin: "0px 10px" }}
                        onClick={() => increaseUser(false)}
                      >
                        -
                      </button>
                    </Col>
                    <Col md="2">
                      <button
                        type="button"
                        className="btn btn-outline-dark"
                        onClick={() => increaseUser()}
                        disabled={!editBasePlan}
                      >
                        +
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Col md="5">
                  <Row>
                    <Col md="5">
                      <h6>Price per additional user per month</h6>
                    </Col>
                    <Col md="2">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          value={pricePerAdditionalUser}
                          disabled={!editBasePlan}
                          onChange={(e) =>
                            setPricePerAdditionalUser(e.target.value)
                          }
                        ></Form.Control>
                      </Form.Group>
                    </Col>

                    <Col md="3">
                      {!editBasePlan ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => setEditBasePlan(!editBasePlan)}
                        >
                          Edit
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={handleOnClickSave}
                        >
                          Save
                        </button>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5%" }}>
                <Col md="3">
                  <Row>
                    <Col md="6">
                      <h6>Onboarding Charge / One-time Setup</h6>
                    </Col>
                    <Col md="5">
                      <Form.Group
                      // style={styles.formInput}
                      >
                        <Form.Control
                          name="pType"
                          type="number"
                          onChange={(e) => setOneTimeCharge(e.target.value)}
                          value={oneTimeCharge}
                          disabled={!editOnBoarding}
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col md="1">
                      {!editOnBoarding ? (
                        <button
                          className="btn btn-primary"
                          onClick={() => setEditOnBoarding(!editOnBoarding)}
                        >
                          Edit
                        </button>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={handleOnClickSave}
                        >
                          Save
                        </button>
                      )}
                    </Col>
                    {/* <Col md="3">
                    <button className="btn btn-primary">Edit</button>
                  </Col> */}
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col md="4">
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>User roles</Form.Label>
                    <Row>
                      <Col md="8">
                        {!addUserRoles ? (
                          <Form.Control
                            as="select"
                            name="pType"
                            // value={pType}
                            // isInvalid={errAt === "pType"}
                            onChange={(e) =>
                              handleSelectUserRole(e.target.value)
                            }
                          >
                            <option value="">Choose...</option>

                            {displaypTypeOptionsUserRoles()}
                          </Form.Control>
                        ) : (
                          <Form.Control
                            name="pType"
                            value={userRole}
                            onChange={(e) => setUserRole(e.target.value)}
                          ></Form.Control>
                        )}
                      </Col>

                      <Col md="4">
                        {!addUserRoles && !selectUserRole ? (
                          <button
                            className="btn btn-primary"
                            onClick={() => setAddUserRoles(!addUserRoles)}
                          >
                            Add
                          </button>
                        ) : !selectUserRole ? (
                          <button
                            className="btn btn-success"
                            onClick={handleOnClickSaveUserRole}
                            disabled={!userRole}
                          >
                            Save
                          </button>
                        ) : selectUserRole && !APP_DEFAULT_ROLES.includes(allUserRole.find((role) => role._id === selectUserRole).name) ? (
                          <button
                            className="btn btn-danger"
                            onClick={handleOnClickDeleteUserRole}
                          >
                            Delete
                          </button>
                        ) : <></>}
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
                <Col md="8">
                  <Form.Group
                  // style={styles.formInput}
                  >
                    <Form.Label>Select Permission</Form.Label>
                    <Row>
                      <Col md="12">
                        <Select
                          isMulti
                          options={transformPermissionsToOptions(allPermision)}
                          value={selectPermision}
                          onChange={handlePermissionChange}
                          isDisabled={!selectUserRole}
                          className="permissions-select"
                          classNamePrefix="select"
                          placeholder="Select permissions..."
                          backspaceRemovesValue={false}
                          noOptionsMessage={() => "No permissions available"}
                          hideSelectedOptions={false}
                          closeMenuOnSelect={false}
                          formatOptionLabel={(option, { context }) => {
                            if (context === 'menu') {
                              return option.label;
                            }
                            return option.combinedLabel || option.label;
                          }}
                          styles={{
                            clearIndicator: (base, state) => ({
                              ...base,
                              "pointer-events": "none",
                              color: 'transparent'
                            }),
                            option: (base, state) => ({
                              ...base,
                              backgroundColor: state.isSelected 
                                ? 'rgba(0, 123, 255, 0.1)'
                                : state.isFocused 
                                  ? 'rgba(0, 0, 0, 0.05)'
                                  : 'transparent',
                              color: '#333',
                              position: 'relative',
                              paddingLeft: '30px',
                              opacity: state.isDisabled ? 0.5 : 1,
                              '&::before': {
                                content: state.isSelected ? '"✓"' : '""',
                                position: 'absolute',
                                left: '10px',
                                color: '#007bff'
                              },
                            })
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                </Col>
              </Row>
              <button
                style={{ float: "right", marginTop: "10%" }}
                className="btn btn-success"
                onClick={handleOnClickSaveAdminPermision}
                disabled={!selectUserRole || !selectPermision}
              >
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </MainComponent>
  );
};

export default Subscription;
